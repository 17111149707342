<template>
  <v-card>
    <v-card-title>Скидки</v-card-title>
    <v-card-text class="pb-0 mb-0" >
      <v-row dense>
        <v-col lg="3" sm="6" cols="12">
          <text-line title="Промокод" :value="order.discountCode" />
        </v-col>
        <v-col lg="3" sm="6" cols="12">
          <text-line title="Период акции" :value="order.discount.dateStart + ' - ' + order.discount.dateEnd" />
        </v-col>
        <v-col lg="6" sm="12" cols="12">
          <text-line title="Программа скидок">
            {{ order.discount.promotion }}
            <span v-if="order.discount.discountTarget==='OrderLinesAmount'">
              <span v-if="order.discount.discountValueType==='Amount'">
                - {{ order.discount.discountValue | money }}
              </span>
              <span v-if="order.discount.discountValueType==='Rate'">
                - {{ order.discount.discountValue }} %
              </span>
              / Товары
            </span>
            <span v-else-if="order.discount.discountTarget==='ShippingAmount'">
              <span v-if="order.discount.discountValueType==='Amount'">
                - {{ order.discount.discountValue | money }}
              </span>
              <span v-if="order.discount.discountValueType==='Rate'">
                - {{ order.discount.discountValue }} %
              </span>
              / Доставка
            </span>
          </text-line>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn x-small color="primary" outlined @click="deleteDialog.show=true">Удалить скидку</v-btn>
    </v-card-actions>
    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление скидки</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление скидки?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteOrderDiscountConfirm">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>

import TextLine from '@/components/ui/TextLine.vue'

export default {
  components: { TextLine },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['delete-discount'],
  data: function() {
    return {
      deleteDialog: {
        show: false
      }
    }
  },
  methods: {
    deleteOrderDiscountConfirm() {
      this.deleteDialog.show = false
      this.$emit('delete-discount')
    }
  }
}
</script>
